import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import weburls from '../Weburls/weburls';
import './Plan.css';

const ToggleSwitch = ({ value, onChange }) => {
  return (
    <label className="switch-plans">
      <input type="checkbox" checked={value} onChange={onChange} style={{ margin: '7px' }} />
      <span className="slider round"></span>
    </label>
  );
};

const SelectNewPlan = () => {
  const FreePlanDescription = [
    '14-day free trial',
    'Access to all features',
    'Unlimited integrations',
    'Automatic bank feeds',
    'Automatic spend categorising',
    'Unlimited Goals',
    'Access to all notifications',
  ];
  const StandardPlanDescription = [
    '14-day free trial',
    'Access to most features',
    '5 total bank & kiwisaver connections',
    'Automatic bank feeds',
    'Automatic spend categorising',
    'Set & track up to 4 goals',
    'Access to all notifications',
  ];
  const ProPlanDescription = [
    '14-day free trial',
    'Access to all features',
    'Unlimited integrations',
    'Automatic bank feeds',
    'Automatic spend categorising',
    'Unlimited Goals',
    'Access to all notifications',
  ];

  const [selectedPlanDuration, setSelectedPlanDuration] = useState('monthly');
  const [selectedPlan, setSelectedPlan] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 600;
  const toggleDarkMode = () => {
    setSelectedPlanDuration((prevPlan) => (prevPlan === 'monthly' ? 'annual' : 'monthly'));
  };
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };
  const paymentAmount = (duration, plan) => {
    console.log('duration', duration);
    console.log('plan', plan);
    if (duration === 'monthly' && plan === 'pro') {
      return 13.8;
    } else if (duration === 'monthly' && plan === 'standard') {
      return 10.35;
    } else if (duration === 'annual' && plan === 'standard') {
      return 103.5;
    } else if (duration === 'annual' && plan === 'pro') {
      return 138;
    } else {
      return 0;
    }
  };
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail, navigate]);

  const handleConfirmPlan = async (selectedPlan, selectedPlanDuration) => {
    console.log('selectedPlan', selectedPlan);
    console.log('selectedPlanDuration', selectedPlanDuration);
    setLoading(true);
    if (selectedPlan) {
      const body = {
        name: userName,
        email: userEmail,
        amount: paymentAmount(selectedPlanDuration, selectedPlan),
        planName: 'monthly Plan',
        planType: selectedPlan,
        plan: selectedPlanDuration,
      };

      try {
        console.log('body', body);
        if (body.amount === 0) {
          const response = await axios.post(weburls.Free_trail_plan, body);
          if (response.data.code === 1) {
            setLoading(false);
            localStorage.setItem('planDescription', response.data.data.planDescription);
            localStorage.setItem('planDuration', response.data.data.planDuration);
            localStorage.setItem('amount', response.data.data.amount);
            navigate(`/accountSetup`);
          } else {
            toast.error(response.data.message);
            setLoading(false);
          }
        } else {
          const response = await axios.post(weburls.Plan_payment_session, body);

          // Handle success
          const session = response.data;
          window.location = session.url;
          // setFormData(response);
          setLoading(false);
        }
      } catch (error) {
        // Handle errors
        if (error.response) {
          toast.error(error.response.data.error);
        } else if (error.request) {
          toast.error('No response received from the server. Please try again.');
        } else {
          toast.error('Payment failed. Please try again.');
        }

        console.error(error);
        setLoading(false);
      }      
      // navigate(`/payments?duration=${selectedPlanDuration}&plan=${selectedPlan}`);
    } else {
      console.error('Please select a plan before confirming.');
    }
  };

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-in {
          animation: slide-in 0.5s ease-out forwards;
        }

        @keyframes slide-in {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(-10px);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
                
              </div>

              <div className="plans-div select-new-plans-div mt-4" ref={formRef}>
                <p className="text-center registerText" style={{ fontSize: '22px' }}>
                  Select your plan
                </p>
                <p className="text-center mb-3 tagtext">Select a plan below to either upgrade or downgrade.</p>
                <div className="plans-div-wrapper">
                  <div style={{ textAlign: 'center', marginRight: '10px' }}>
                    <p
                      className=""
                      style={{
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#000000',
                        marginTop: '10px',
                        fontFamily: 'Neurialgrotesk',
                      }}
                    >
                      Monthly plans
                    </p>
                  </div>
                  <div className="button-blck">
                    <ToggleSwitch value={selectedPlanDuration === 'annual'} onChange={toggleDarkMode} />
                  </div>
                  <div style={{ textAlign: 'center', marginLeft: '0px' }}>
                    <p
                      className=""
                      style={{
                        fontSize: '18px',
                        fontWeight: 500,
                        color: '#000000',
                        marginTop: '10px',
                        fontFamily: 'Neurialgrotesk',
                      }}
                    >
                      Annual plans
                    </p>
                  </div>
                </div>

                <div className="select-plan-billing-wrapper">
                  <div className="plan-box-mobile-view-standard">
                    <p className="select-plans-div-text-mobile">
                      Standard - {selectedPlanDuration === 'monthly' ? '$10.35' : '$103.50'}
                      <span className="select-plans-div-sub-text-mobile">
                        /{selectedPlanDuration === 'monthly' ? 'Month' : 'year'}
                      </span>
                    </p>
                    <p className="select-plans-text-mobile">
                      Our most popular plan with the essentials. Includes access to most features such as dashboard,
                      budget, cashflow, 4 goals, and 5 connections.
                    </p>
                    <div className="select-button-box">
                      <button
                        className="select-plan-button-green"
                        style={{
                          color: selectedPlan === 'standard' ? '#FFF' : '#145BFF',
                          backgroundColor:
                            selectedPlan === 'standard' ? 'rgba(254, 254, 254, 0.4)' : 'rgba(254, 254, 254)',
                        }}
                        onClick={() => handleConfirmPlan('standard', selectedPlanDuration)}
                      >
                        {selectedPlan === 'standard' ? 'Plan Selected' : 'Select Plan'}
                      </button>
                    </div>
                  </div>

                  <div className="plan-box-mobile-view-pro">
                    <p className="select-plans-div-text-mobile">
                      Pro - {selectedPlanDuration === 'monthly' ? '$13.80' : '$138'}
                      <span className="select-plans-div-sub-text-mobile">
                        /{selectedPlanDuration === 'monthly' ? 'Month' : 'year'}
                      </span>
                    </p>
                    <p className="select-plans-text-mobile">
                      Ideal for families and finance fanatics, full access to all features including, dashboard, budget,
                      cashflow, goals, net worth and unlimited connections.{' '}
                    </p>
                    <div className="select-button-box">
                      <button
                        className="select-plan-button-green"
                        style={{
                          color: selectedPlan === 'pro' ? '#FFF' : '#56D77A',
                          backgroundColor: selectedPlan === 'pro' ? 'rgba(254, 254, 254, 0.4)' : 'rgba(254, 254, 254)',
                        }}
                        onClick={() => handleConfirmPlan('pro', selectedPlanDuration)}
                      >
                        {selectedPlan === 'pro' ? 'Plan Selected' : 'Select Plan'}
                      </button>
                    </div>
                  </div>
                </div>                
                {/* {loading === true ? (
                  <OverlayContainer>
                    <div>
                      <Loading />
                    </div>
                  </OverlayContainer>
                ) : (
                  ''
                )} */}
                {/* <div>
                  <button className="confirm-plan mb-5" onClick={handleConfirmPlan}>
                    Confirm plan
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SelectNewPlan;



