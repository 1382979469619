import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners'; // Import ClipLoader
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tick from '../../assests/images/tick.svg';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';
import weburls from '../Weburls/weburls';

const CancelFeedback = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reasons, setReasons] = useState([
    'Too expensive',
    'I need more features',
    'Customer service was less than expected',
    'Ease of use was less than expected',
    'I found an alternative',
    'Lack of integration providers',
    'Other',
  ]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [message, setMessage] = useState('');
  const [otherSelected, setOtherSelected] = useState(false);
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail]);

  const fetchedData = useCallback(async () => {
    const body = {
      email: userEmail,
    };
    try {
      const response = await axios.post(weburls.Get_CurrentPlan, body);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchedData();
  }, [fetchedData]);

  const getPlanDetail = (amount) => {
    if (Number(amount) === 10.35) {
      return 'Standard Monthly';
    } else if (Number(amount) === 103.5) {
      return 'Standard Yearly';
    } else if (Number(amount) === 13.8) {
      return 'Pro Monthly';
    } else if (Number(amount) === 138) {
      return 'Pro Yearly';
    } else if (Number(amount) === 82.8) {
      return 'Early Bird Annual Pro Plan';
    } else if (Number(amount) === 0) {
      return 'Free Trial';
    } else {
      return 'Free Trial';
    }
  };

  const onDeleteSubmit = async () => {
    if (selectedReasons.length > 0) {
      setLoading(true); // Start loading
      const planName = getPlanDetail(data?.amount);
      const cancelbody = {
        email: userEmail,
      };
      const body = {
        name: userName,
        email: userEmail,
        planName: planName,
        reasons: selectedReasons,
        message: message,
      };
      await axios.post(weburls.Cancel_plan_feedback, body);
      try {
        await axios.post(weburls.Cancel_current_plan, cancelbody);
      } catch (error) {
        console.error(error);
      }
      setLoading(false); // Stop loading
      navigate(`/cancelPlanMessage`);
    } else {
      toast.error('Please provide your feedback.');
    }
  };

  function ReasonCheckbox({ reason }) {
    const isSelected = selectedReasons.includes(reason);
    const isOtherSelected = isSelected && reason === 'Other';
    setOtherSelected(isOtherSelected);
    const handleClick = () => {
      if (isSelected) {
        setSelectedReasons(selectedReasons.filter((r) => r !== reason));
      } else {
        setSelectedReasons([...selectedReasons, reason]);
      }
    };

    return (
      <div
        htmlFor={`reason-${reason}`}
        className={`feedback-reason-div ${isSelected ? 'feedback-div-green-border' : 'feedback-div-default-border'}`}
        onClick={handleClick}
      >
        <label htmlFor={`reason-${reason}`} className="feedback-cancel-text">
          {reason}
        </label>
        <div className={`checkbox-round ${isSelected ? 'selected' : ''}`}>
          {isSelected && <img src={Tick} alt="Tick" className="tick-icon" />}
        </div>
      </div>
    );
  }

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }

        .checkbox-round {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #ccc;
          display: inline-block;
          margin-left: 10px;
          position: relative;
        }

        .checkbox-round.selected {
          background-color: #469b88; /* Theme green color */
          border-color: #469b88; /* Theme green color */
        }

        .tick-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
        }

        .Toastify__toast-container {
          position: fixed;
          top: 20px;
          right: 20px;
          z-index: 9999;
        }
      `}</style>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom mb-5">
          <div className="row d-flex justify-content-center align-items-center h-100 mb-5">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                className="logoCss text-center mb-4"
                style={{
                  margin: '30px',
                }}
                ref={logoRef}
              >
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp mt-3 mb-5" ref={formRef}>
                <p
                  className="text-center welcomeText mt-3 mb-5"
                  style={{
                    fontSize: '30px',
                  }}
                >
                  Sorry to see you go 😕
                </p>
                <p className="text-center mt-1 tagtext">
                  Before we cancel your plan, please select some cancellation reasons below.
                </p>
                <div className="mt-4">
                  <div>{/* <p className="plan-selected-text">Current Plan:</p> */}</div>
                  <div className="row">
                    <div className="col-lg-12 account-assets-column  box-radis-25">
                      <form>
                        {reasons.map((reason) => (
                          <ReasonCheckbox key={reason} reason={reason} />
                        ))}
                        {otherSelected && (
                          <textarea
                            value={message}
                            name="message"
                            id="form3Example3"
                            className="message-cancel-div"
                            style={{ fontFamily: 'Neurial Grotesk', paddingTop: '5px', paddingLeft: '5px' }}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        )}
                      </form>
                    </div>

                    <div className="text-center mt-2 pt-2 mb-4">
                      <button
                        type="submit"
                        className={`comn-btn verify-btn ${loading ? 'sign-btn-load' : 'sign-btn'}`}
                        onClick={onDeleteSubmit}
                      >
                        {loading ? <ClipLoader size={15} color={'#ffffff'} /> : 'Submit feedback'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CancelFeedback;
